import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "./reducers";
import { LOGIN_PAGE_LOGIN_BUTTON_TEXT } from "./constants/messages";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "./utils/PerformanceTracker";
import AnalyticsUtil from "./utils/AnalyticsUtil";
import Button from "./components/editorComponents/Button";
import { IS_DEPLOY } from "./index";

export default function RedirectComponent() {
  useEffect(() => {
    if (IS_DEPLOY) {
      window.location.href =
        window.location.origin + "/oauth2/authorization/enduser";
    } else {
      if (window.location.search) {
        window.location.href =
          window.location.origin + "/oauth2/authorization/enduser";
      }
    }
  }, []);

  const devRedirect = useCallback(() => {
    window.location.href =
      window.location.origin + "/oauth2/authorization/appcreator";
  }, []);
  const userRedirect = useCallback(() => {
    window.location.href =
      window.location.origin + "/oauth2/authorization/enduser";
  }, []);

  return (
    <>
      {IS_DEPLOY ? (
        <></>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{ width: "100%", textAlign: "center", fontSize: "20px" }}
          >
            Go to LogIn Page
          </span>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              width: "180px",
              justifyContent: "space-around",
            }}
          >
            <Button
              type="submit"
              text={" User "}
              intent="primary"
              filled
              size="large"
              onClick={userRedirect}
            />
            <Button
              type="submit"
              text={" Dev "}
              intent="primary"
              filled
              size="large"
              onClick={devRedirect}
            />
          </div>
        </div>
      )}
    </>
  );
}
