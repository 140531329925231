import React, { useEffect } from "react";
import ApplicationApi from "../../api/ApplicationApi";

function getPage(res: any) {
  try {
    const pages = res?.data?.applications[0].pages;
    for (const item of pages) {
      if (item.default) {
        return item.id;
      }
    }
    return pages[0].id;
  } catch (e) {
    return null;
  }
}

export const MainClientPage = (props: any) => {
  useEffect(() => {
    (async () => {
      const res: any = await ApplicationApi.getAllApplicationAndUser();
      const pages = getPage(res);
      if (pages) {
        props.history?.push(`/${pages}`);
      }
    })();
  }, []);

  return <div></div>;
};

export default MainClientPage;
